import request from "@/utils/request";

export const getList = (page,size,params)=>{
    return request({
        url:'/api/hado/balance-bill/list',
        method:'get',
        params:{...params,page,size}
    });
}

export const getDetail = (id)=>{
    return request({
        url:'/api/hado/balance-bill/detail',
        method:'get',
        params:{id}
    })
}

export const exportExcel = (id)=>{
    return request({
        url:'/api/hado/balance-bill/export-excel',
        method:'get',
        params:{id,t:Math.random()},
        responseType:'blob'
    })
}
