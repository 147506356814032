<template>
    <div class="rb-view">
        <div class="bill-view">
            <div class="bill-container">
                <div style="position:relative;">
                    <div style="font-size:18px;font-weight: bold;line-height:18px;text-align: center;margin:36px 0;">{{bill_detail.bill_name}} <span v-if="bill_detail&&bill_detail.id&&bill_detail.is_end === false" style="color:#F56C6C;">(未出账)</span> </div>
                    <div style="position:absolute;right:0;top:50%;transform: translateY(-50%)">
                        <el-button @click="onExportTap">导出明细</el-button>
                        <!--                <el-button>打印汇总</el-button>-->
                    </div>
                </div>
                <div style="display:flex;align-items:center;justify-content: space-between;color:#646566;font-size:12px;">
                    <div>平台：<span>{{bill_detail.account_name}}</span></div>
                    <div>币种：<span>{{bill_detail&&bill_detail.id ? '人民币':''}}</span></div>
                    <!--                <div>账单周期：2020-11-17 00:00:00 至 2020-11-17 23:59:59</div>-->
                    <div>账单周期：{{timeFormatter(bill_detail.begin_at)}} 至 {{timeFormatter(bill_detail.end_at)}}</div>
                </div>
                <div style="margin-top:30px;">
                    <div class="bill-bar-head">平台余额</div>
                </div>
                <div class="sum-dash">
                    <div class="sum-dash-col"><div class="col-head">本期期初(元)</div><div class="col-val">{{bill_detail.previous_total_balance||'-'}}</div></div>
                    <div class="sum-dash-col"><div class="col-head">本期收入(元)</div><div class="col-val" style="color:#409EFF">
                        {{bill_detail.income||'-'}}</div></div>
                    <div class="sum-dash-col"><div class="col-head">本期支出(元)</div><div class="col-val" style="color:#F56C6C;">{{bill_detail.outcome||'-'}}</div></div>
                    <div class="sum-dash-col"><div class="col-head">本期结余(元)</div><div class="col-val">{{bill_detail.total_balance}}</div></div>
                </div>

                <div class="dt-col-table-view">
                    <div style="width:100%;display:flex;">
                        <div class="dt-col-table" style="margin-right:10px;">
                            <div class="dt-col-table-head">收入类型</div>
                            <div class="dt-col-table-body">

                                <div v-for="(item) in (bill_detail.income_group_list||[])" class="dt-col-table-item" v-bind:key="item.id">
                                    <div class="dt-col-table-item-left">{{item.record_type_name}}</div>
                                    <div class="dt-col-table-item-right">
                                        <div class="item-sum-val-view">
                                            <div>{{item.amount}} 元</div>
                                            <div>{{item.count}} 笔</div>
                                        </div>
<!--                                        <a target="_blank" class="dt-item-nav">明细</a>-->
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="dt-col-table">
                            <div class="dt-col-table-head">收入类型</div>
                            <div class="dt-col-table-body">
                                <div v-for="(item) in (bill_detail.outcome_group_list||[])" v-bind:key="item.id" class="dt-col-table-item">
                                    <div class="dt-col-table-item-left">{{item.record_type_name}}</div>
                                    <div class="dt-col-table-item-right">
                                        <div class="item-sum-val-view">
                                            <div>{{item.amount}} 元</div>
                                            <div>{{item.count}} 笔</div>
                                        </div>
<!--                                        <a target="_blank" class="dt-item-nav">明细</a>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dt-sum-view">

                        <div class="dt-sum-col" style="margin-right:10px;">
                            <div class="dt-sum-col-item">
                                <div class="dt-sum-col-left">收入合计</div>
                                <div class="dt-sum-col-right">
                                    <div class="dt-sum-val-view">
                                        <div>{{bill_detail.income + ' 元'}}</div>
                                        <div>{{bill_detail.income_count + ' 笔'}}</div>
                                    </div>
                                    <div class="dt-item-nav"></div>
                                </div>
                            </div>
                        </div>

                        <div class="dt-sum-col">
                            <div class="dt-sum-col-item">
                                <div class="dt-sum-col-left">支出合计</div>
                                <div class="dt-sum-col-right">
                                    <div class="dt-sum-val-view">
                                        <div>{{bill_detail.outcome + ' 元'}}</div>
                                        <div>{{bill_detail.outcome_count+ ' 笔'}}</div>
                                    </div>
                                    <div class="dt-item-nav"></div>
                                </div>
                            </div>
                        </div>

                    </div>



                </div>


            </div>
        </div>
    </div>
</template>

<script>
    import {getDetail,exportExcel} from "@/api/hado/balance-bill";
    import {formatTimestamp} from "@/utils/time";

    export default {
        name: "balance-bill-detail",
        data(){
            return{
                bill_detail:{},
            }
        },
        created(){
            this.initPage();
        },
        methods:{
            initPage(){
                if(this.$route.query.id){
                    getDetail(this.$route.query.id).then(resp=>{
                        this.bill_detail = resp.data;
                        console.log(resp)
                    })
                }else{
                    this.$confirm('参数错误').then(()=>{
                        this.$router.go(-1)
                    })
                }
            },
            onExportTap(){
                exportExcel(this.bill_detail.id).then(res=>{
                    const blob = new Blob([res.data]);//new Blob([res])中不加data就会返回下图中[objece objece]内容（少取一层）
                    console.log('blob:',blob)
                    if(res.data.type!=="application/force-download"){
                        // console.log(res)
                        console.log("文件类型不对:",blob.type)
                        this.$message.warning("你无法下载订单")
                        return
                    }
                    const fileName = 'hado_bill_'+this.bill_detail.bill_date_tip+ '.xlsx';//下载文件名称

                    const elink = document.createElement('a');
                    elink.download = fileName;
                    elink.style.display = 'none';
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // 释放URL 对象
                    document.body.removeChild(elink);
                }).catch(err=>{
                    this.$message.error("下载异常")
                    console.log("catch:",err)
                })
            },

            timeFormatter(cellValue){
                return formatTimestamp(cellValue)
            },
        }
    }
</script>

<style scoped>
    .bill-view{background:#FFF;padding:20px;min-width:1000px;}
    .bill-container{}
    .bill-bar-head{display: flex;justify-content: flex-start;align-content: center;margin-bottom: 16px;padding: 10px 12px;min-height:40px;box-sizing: border-box;background-color:#f7f8fa;}
    .bill-bar-head::before{background-color: #155bd4;content: "";width: 3px;height: 14px;margin-right: 8px;}
    .sum-dash{margin: 10px auto;padding: 15px 0;background-color: #f8f8f8;display: flex;justify-content: space-around;}
    .sum-dash-col{}
    .sum-dash-col .col-head{font-size: 14px;color: #646566;margin-bottom: 10px;}
    .sum-dash-col .col-val{font-size: 20px;font-weight: bolder;}
    .dt-col-table-view{}
    .dt-col-table{flex:1;}
    .dt-col-table-head{background-color:#f7f8fa;font-size: 14px;font-weight: 500;padding:8px 10px;height:56px;display:flex;align-items: center;}
    .dt-col-table-item{display:flex;justify-content: space-between;align-items: center;padding:8px 10px;min-height:56px;font-size: 14px;}
    .dt-col-table-item:hover{background: #edf4ff;}
    .dt-col-table-item .dt-col-table-item-left{}
    .dt-col-table-item .dt-col-table-item-right{display:flex;align-items:center;}
    .dt-col-table-item .item-sum-val-view{text-align:right;margin-right:15px;line-height:1.5;}
    .dt-sum-view{display: flex;align-items: center;justify-content: space-between;}
    .dt-sum-view .dt-sum-col{flex:1;border-top: 1px solid #e5e5e5;border-bottom: 1px solid #e5e5e5;}
    .dt-sum-col-item{display:flex;flex-direction:row;justify-content: space-between;align-items: center;padding:8px 10px;font-size: 14px;}
    .dt-sum-col-right{display:flex;flex-direction:row;font-weight:bold;}
    .dt-sum-val-view{margin-right:15px;}
    .dt-sum-val-view>*{line-height: 1.5;}
    .dt-sum-col{text-align:right;}
    .dt-item-nav{color:#155bd4;width:40px;text-align:center;}
</style>
